const altByIcon = {
  'back_button': 'Back button icon',
  'back': 'Back icon',
  'caret-left': 'Caret left icon',
  'caret-right': 'Caret tight icon',
  'chevron': 'Chevron icon',
  'close': 'Close icon',
  'dots': 'Dots icon',
  'loader': 'Loader icon',
  'loader-white': 'Loader icon',
  'menu' : 'Menu icon',
  'tick': 'Tick icon',
  'x': 'X sign icon',
}

export type TypeAppIcon = keyof typeof altByIcon
export const iconByName = (iconName: TypeAppIcon) => ({
  type: 'svg' as const,
  src: `/images/svg/i--${iconName}.svg`,
  alt: altByIcon[iconName],
  size: { width: 24, height: 24 }
})
