import type { FC, ReactNode, MouseEvent } from 'react'
import NextLink from 'next/link'
import type { TypeHref } from '@/schema'

type Props = {
  // arialabel: string,
  ariaDisabled?: boolean
  children: ReactNode
  className?: string
  href: TypeHref
  locale?: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  /**
  * @remarks
  * @see https://nextjs.org/docs/app/api-reference/components/link#prefetch
  */
  prefetch?: null | boolean
  rel?: 'alternate' | 'author' | 'bookmark' | 'help' | 'license' | 'me' | 'next' | 'prev' | 'search' | 'tag'
  /**
   * Defaults to false. When true, next/link will replace the current history state instead of adding a new URL into the browser’s history stack.
   */
  replace?: boolean
  /**
   * Defaults to true. The default behavior of <Link> is to scroll to the top of a new route or to maintain the scroll position for backwards and forwards navigation. When false, next/link will not scroll to the top of the page after a navigation.
  */
  scroll?: boolean
  title?: string
}

export const Link: FC<Props> = ({ href, children, ariaDisabled, rel, locale, title, className, onClick, prefetch = null, replace = false }) => {
  return (
    <NextLink href={href} passHref {...locale && { locale }} {...rel && { rel }} {...ariaDisabled && { 'aria-disabled': ariaDisabled }} {...title && { title }} {...className && { className }} {...onClick && { onClick }} prefetch={prefetch} {...replace && { replace }}>
      {children}
    </NextLink>
  )
}
