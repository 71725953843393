/* eslint-disable quotes */
export const jsonLdFaqs = (_data, _locale) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What is AI-generated art?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "AI-generated art refers to artwork that is created with the assistance of artificial intelligence technologies. Artists and developers use AI algorithms to generate new pieces of art, which can range from paintings and drawings to digital creations and even music."
        }
      },
      {
        "@type": "Question",
        "name": "How can I access AI-generated images on your platform?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To access AI-generated images on our platform, users are required to register for an account. Once registered, you can browse our extensive gallery of AI-generated images, categorized by various themes and styles."
        }
      }
    ]
  }

  return JSON.stringify(schema)
}
