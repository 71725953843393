/* eslint-disable quotes */
import { LOCALE } from '@/utils'
import type { Lang } from '@/schema'

// All JSONLD availables
import { jsonLdHomepage } from './jsonld/homepage'
import { jsonLdFaqs } from './jsonld/faqs'

const jsonLdByType = {
  'home': (data, locale) => jsonLdHomepage(data, locale),
  'faqs': (data, locale) => jsonLdFaqs(data, locale),
}

type TypeGetJsonld = {
  type: keyof typeof jsonLdByType,
  data?: {
    [key:string]: any
  },
  locale?: Lang
}

export const getJsonld = ({ type, data = null, locale = LOCALE as Lang }:TypeGetJsonld) => jsonLdByType[type](data, locale)

