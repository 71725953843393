import { useEffect, useState } from 'react'

type TypeScrollStates = 'TOP' | 'SCROLLING_DOWN' | 'SCROLLING_UP'
type TypeSCROLL = {
  currentState: TypeScrollStates
  lastScroll: number
  getCurrentState: (currentScroll, maxHeight) => TypeScrollStates
}

const SCROLL: TypeSCROLL = {
  currentState: 'TOP',
  lastScroll: 0,
  getCurrentState(currentScroll, maxHeight) {
    if (currentScroll < maxHeight) this.currentState = 'TOP'
    else {
      if (this.lastScroll < currentScroll) this.currentState = 'SCROLLING_DOWN'
      else if (this.lastScroll > currentScroll) this.currentState = 'SCROLLING_UP'
    }

    this.lastScroll = currentScroll
    return this.currentState
  }
}

export const useScrollTracker = (maxHeight: number = 60) => {
  const [scrollState, setScrollState] = useState<TypeScrollStates>('TOP')

  const trackScroll = () => {
    const newState = SCROLL.getCurrentState(window.scrollY, maxHeight)

    setScrollState((currentState) => {
      return (currentState !== newState) ? newState : currentState
    })
  }

  const debounceTrackScroll = () => requestAnimationFrame(trackScroll)

  useEffect(() => {
    window.addEventListener('scroll', debounceTrackScroll, { passive: true })
    return () => window.removeEventListener('scroll', debounceTrackScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxHeight])

  return scrollState
}
