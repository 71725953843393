import { css } from 'styled-components'
// import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value:number, factor = null) => `${value / (factor ?? rem.baseMobile)}rem`
}

export const font12_12 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font13_15 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(13, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(15.34, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font15_18 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(18.75, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font18_18 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(18.75, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font20_22 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(20, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(22.6, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font25_27 = (mobile: boolean = true, weight: 400 | 500 | 600 = 400) => css`
  font-size: ${rem.get(25, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(27, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font32_35 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(32, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(35.2, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font40_44 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(40, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(44, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font45_40 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(45, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(40, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font45_44 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(45, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(44, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font62_55 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(62, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(55.8, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font70_70 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(62, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(62, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font170_153 = (mobile: boolean = true, weight: 400 | 500 = 400) => css`
  font-size: ${rem.get(170, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(153, rem[mobile ? 'baseMobile' : 'base'])};
`
