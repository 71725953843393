export const slugify = (text:string):string => text
  .toString()          // Cast to string (optional)
  .normalize('NFKD')  // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
  .toLowerCase()      // Convert the string to lowercase letters
  .trim()             // Remove whitespace from both sides of a string (optional)
  .replace(/\s+/g, '-')       // Replace spaces with -
  .replace(/[^\w\-]+/g, '')   // Remove all non-word chars
  .replace(/\-\-+/g, '-')     // Replace multiple - with single -


export const scrollToTop = () => {
  window?.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
}


export function getDynamicText(text: string, variables: { [key: string]: string | number }): string {
  // Use a regular expression to find all placeholders between double curly braces
  const placeholderRegex = /\{\{([^}]+)\}\}/g

  // Replace each placeholder with the corresponding value from the variables object
  const dynamicText = text.replace(placeholderRegex, (match, placeholder) => {
    // Trim the placeholder to remove any leading or trailing spaces
    const keyName = placeholder.trim()

    // Check if the keyName exists in the variables object
    if (variables.hasOwnProperty(keyName)) {
      // If the keyName exists, return its value
      return variables[keyName].toString()
    } else {
      // If the keyName does not exist, return the original placeholder
      console.warn(`keyName: ${keyName} doesn't exist for the text: ${text}`)
      return match
    }
  })

  return dynamicText
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
