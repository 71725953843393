const defaultTheme = {
  colors: {
    'background': 'rgb(30, 30, 30)',
    'text': 'rgb(255, 255, 255)',
  },
  fonts: {
    // eslint-disable-next-line quotes
    'family': `'Montserrat', sans-serif`,
    // eslint-disable-next-line quotes
    'familyCode': `'Courier New', monospace`,
    'size': 16,
    'lineHeight': 24 / 16,
    'headersize': 20,
    'headerLineHeight': 24 / 20
  }
}

const themeProject = {
  colors: {
    bright_blue: '#0066FF',
    dark_blue: '#002D70',
    background: '#EBEAF1',
    text: '#FFFFFF',
    beige: '#FBF7ED',
    blue: '#02E1FF',
    green: '#10FF70',
    grey: '#898989',
    white: '#FFFFFF',
    black: '#121212',
    input_error: '#FF3945',
  },
  fonts: {
    primaryFont: 'sans-serif'
  }
}

export const theme = {
  colors: {
    ...defaultTheme.colors,
    ...themeProject.colors
  },
  fonts: {
    ...defaultTheme.fonts,
    ...themeProject.fonts
  }
}

