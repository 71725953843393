'use client'
import Flickity, { type FlickityOptions } from 'react-flickity-component'
import { type FC, type ReactNode, memo, useEffect, useState } from 'react'
import { iOS } from '@/hooks'

type Props = {
  children: ReactNode,
  className?: string,
  reloadOnUpdate?: boolean
  dynamicSlider?: boolean
  elementType?: 'div' | 'section' | 'aside' | 'dd' | 'ul',
  options?: FlickityOptions,
  onChange?: any,
  randomIndex?: number

}

const defaultOptions:FlickityOptions = {
  accessibility: false,
  autoPlay: false,
  cellAlign: 'left',
  contain: true,
  draggable: true,
  dragThreshold: 5,
  freeScroll: true,
  freeScrollFriction: 0.075,
  friction: 0.15,
  groupCells: false,
  initialIndex: 0,
  lazyLoad: 2,
  pauseAutoPlayOnHover: true,
  prevNextButtons: false,
  pageDots: false,
  resize: true,
  selectedAttraction: 0.01,
  wrapAround: false
}

//   // just in case the dragEnd event doesn't fire:
//   document.body.addEventListener('touchstart', (ev) => {
//     document.ontouchmove = () => true;
//   });
// }

const sliderBeingDragged = 'slider-being-dragged'
function dragEnd() {
  document.ontouchmove = () => true
  setTimeout(() => {
    document.body.classList.remove(sliderBeingDragged)
  }, 50)
}

function dragMove() {
  document.ontouchmove = () => false
  document.body.classList.add(sliderBeingDragged)
}

function dragStart() {
  document.ontouchmove = () => iOS() ? false : false
}

export const SliderFlickity: FC<Props> = memo(({ children, className = '', elementType = 'div', options = {}, onChange = null, reloadOnUpdate = false, dynamicSlider = false, randomIndex = 0 }) => {
  const [flickityRef, setFlickityRef] = useState(null)

  useEffect(() => {
    if (!flickityRef) return

    flickityRef.on('dragStart', dragStart)
    flickityRef.on('dragMove', dragMove)
    flickityRef.on('dragEnd', dragEnd)
    if (onChange) flickityRef.on('change', onChange)
    return () => {
      flickityRef.off('dragStart', dragStart)
      flickityRef.off('dragMove', dragMove)
      flickityRef.off('dragEnd', dragEnd)
      if (onChange) flickityRef.off('change', onChange)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flickityRef])


  useEffect(() => {
    if(flickityRef) {
      flickityRef.on( 'select', function() {
        flickityRef.playPlayer()
      })
    }
  }, [flickityRef])


  useEffect(() => {
    if (typeof randomIndex !== 'number' || !flickityRef) return
    flickityRef.select(randomIndex, false, false)
  }, [randomIndex, flickityRef])

  return (
    <Flickity
      flickityRef={(ref) => setFlickityRef(ref)}
      className={`slider ${className}`}
      elementType={elementType}
      options={{ ...defaultOptions, ...options }}
      disableImagesLoaded={false} // default false
      reloadOnUpdate={reloadOnUpdate} // default false
      static={!dynamicSlider} // default false
    >
      {children}
    </Flickity>
  )
})
