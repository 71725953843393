import { type FC, type MouseEvent, memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Figure } from '.'
import { iconByName, type TypeAppIcon } from '../utils'
import { font12_12, mq, vw } from '../styles'

type TypeToast = 'info' | 'success' | 'warning' | 'error' | 'loading'
export interface iToastProps {
  type: TypeToast
  message: string
  closeNeeded?: boolean
  icon?: TypeAppIcon
  onClose?: (e?: MouseEvent<HTMLButtonElement>) => void
  timeout?: number
}


const getColorByType = (type: TypeToast) => {
  switch (type) {
    case 'info':
      return '#F3EFE6'
    case 'success':
      return '#6F4EFB'
    case 'warning':
      return '#E92D52'
    case 'error':
      return '#E92D52'
    default:
      return '#F3EFE6'
  }
}

export const Toast: FC<iToastProps> = memo(({ message, type, icon, timeout = 5000, closeNeeded = false, onClose }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (!closeNeeded) {
      timer = setTimeout(() => {
        setVisible(false)
      }, timeout)
    }

    return () => { clearTimeout(timer) }
  }, [timeout, closeNeeded])

  const handleClose = (e:MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // Prevent the event from reaching the ToastContainer
    setVisible(false)
    if (onClose) onClose()
  }

  return (
    <AnimatePresence mode='wait'>
      {visible &&
        <Div $type={type} role='alert' aria-relevant='additions' variants={variants} initial='initial' animate='animate' exit='exit'>
          {icon && <Figure media={iconByName(icon as TypeAppIcon)} />}
          <p>{message}</p>
          {closeNeeded && <button onClick={handleClose} name='Cerrar' aria-label='Cerrar'>
            <Figure media={iconByName('close')}/>
          </button>}
        </Div>
      }
    </AnimatePresence>
  )
})

const Div = styled(motion.div)<{ $type: TypeToast }>`
  ${font12_12(true, 400)}
  align-items: center;
  background-color: ${({ $type }) => getColorByType($type)};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: ${({ $type }) => ($type === 'warning' || $type === 'error' || $type === 'success') ? '#F3EFE6' : '#4E1114'};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  justify-content: space-between;
  margin: ${vw(4, 'mobile')} 0;
  padding: ${vw(4, 'mobile')} ${vw(16, 'mobile')};
  pointer-events: auto;
  z-index: 999;
  width: 100vw;
  width: 100dvw;

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(4, 'tablet')} 0;
    padding: ${vw(4, 'tablet')} ${vw(16, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    ${font12_12(false, 400)}
    margin: ${vw(4, 'desktop')} 0;
    padding: ${vw(4, 'desktop')} ${vw(16, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin: 4px 0;
    padding: 4px 16px;
  }

  p {
    flex-grow: 2;
    flex-shrink: 0;
    text-align: center;
    width: 100%;
  }
`

const variants = {
  initial: {
    opacity: 0,
    x: '100%',
    transition: { duration: .4 }
  },
  animate: {
    opacity: 1,
    x: '0%',
    transition: { duration: .4 }
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: { duration: .3 }
  }
}

export const ToastWrapper = styled.aside`
  bottom: 0;
  left: 0;
  max-width: 100vw;
  pointer-events: none;
  position: fixed;
  right: 0;
  transition: 300ms all linear;
  user-select: none;
  width: 100%;
  z-index: 500;

  > div {
    &:last-child {
      margin-bottom: 0;
    }
  }
`
