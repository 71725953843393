import { createContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { LOCALE } from '@/utils'
import { getDictionary } from '@/app/dictionaries'
import { useParams } from 'next/navigation'
import enDictionary from '../app/dictionaries/en.json'

const DEFAULT_LANG = LOCALE.split('-')[0]

export const TranslationsContext = createContext<any>({
  dictionary: enDictionary
})

type Props = {
  children: JSX.Element
}

let prevLang = DEFAULT_LANG
export const TranslationsProvider: FC<Props> = ({ children }) => {
  const { lang } = useParams()
  const [dictionary, setDictionary] = useState(enDictionary)

  useEffect(() => {
    async function updateDictionary(lang) {
      const incomingDictionary = await getDictionary(lang)
      setDictionary(incomingDictionary)
    }

    if (!dictionary) updateDictionary(lang ? lang + '' : DEFAULT_LANG)
    if (lang !== prevLang) {
      updateDictionary(lang ? lang + '' : DEFAULT_LANG)
      prevLang = lang ? lang + '' : DEFAULT_LANG
    }
  }, [lang, dictionary])

  return (
    <TranslationsContext.Provider value={{ dictionary }}>
      {children}
    </TranslationsContext.Provider>
  )
}
