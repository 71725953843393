import { type FC, type ReactNode, createContext, useState, useCallback, useRef } from 'react'
import { Toast, type iToastProps, ToastWrapper } from '../components/Toast'

interface iToastState extends iToastProps {
  id: number;
}

export interface ToastContextProps {
  addToast: (toastData: iToastProps) => void;
}

type Props = {
  children: ReactNode
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined)

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<iToastState[]>([])
  const toastIdRef = useRef(0)

  const addToast = useCallback((toastData: iToastProps) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { ...toastData, id: toastIdRef.current++ },
    ])
  }, [setToasts])

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastWrapper aria-live='assertive' aria-atomic='true'>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            {...toast}
            onClose={() => setToasts((prev) => prev.filter((t) => t.id !== toast.id))}
          />
        ))}
      </ToastWrapper>
    </ToastContext.Provider>
  )
}
